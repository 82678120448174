import { Component, ViewEncapsulation, HostListener, OnInit, OnDestroy, TemplateRef,ViewChild } from '@angular/core';
import { Helper } from '../shared/helper';
import { DeliverySettingService } from '../shared/delivery-setting.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
declare var google:any;
import { CartService } from '../shared/cart.service';
import { LoginRegisterModalComponent } from '../login-register-modal/login-register-modal.component';
import { DeliveryErrorModalComponent } from '../delivery-error-modal/delivery-error-modal.component';
import {Location} from "@angular/common";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./home.component.scss'],
  providers: [Helper,DeliverySettingService,CartService]
})
export class HomeComponent implements OnInit, OnDestroy {

  store_open_day: string = '';
  current_store: any = {
    close: false
  };
  is_language_dropdown:boolean=false;
  offer_list:any[] = [];
  city_list: any[] = [];
  map:any;
  modalRef:any;
  is_show_time_popup:boolean = false;
  page_type: number = 1;
  hover_type:number=1;

  session_subscriber:any;
  user_id: string = '';
  server_token: string = '';
  modal_ref:any;

  @ViewChild('AccountPopup') AccountPopup!: LoginRegisterModalComponent
  @ViewChild('ScopeOfDelivery') ScopeOfDelivery!: DeliveryErrorModalComponent

  @HostListener('window:popstate', ['$event'])
    onBrowserBackBtnClose(event: Event) {
      if(this.modal_ref){
        this.modal_ref.close();
      }
    }

  constructor(public helper: Helper,private location: Location,  public cart_service:CartService, public delivery_setting_service: DeliverySettingService, private modalService: NgbModal) { }

  openModal(template: TemplateRef<any>) {
    this.modal_ref = this.modalService.open(template, { centered: true, size: 'lg', modalDialogClass: 'see-menu-popup popup-mid', });
  }

  openModal3(template: TemplateRef<any>) {
    this.modal_ref = this.modalService.open(template, { centered: true, size: 'lg', modalDialogClass: 'alert-popup popup-mid', });
  }
  

  button_hover(hover_type:number){
    this.hover_type = hover_type;
  }

  async ngOnInit(){
    
    var detail = this.helper.common_data.observable.subscribe(async (data) => {
      if (data) {
        if (detail) {
          detail.unsubscribe();
        }
        if(this.helper.router.url=='/home/table'){
          if(this.helper.website_data.detail.is_order_pay && this.helper.website_data.detail.order_pay_status==2){
            this.helper.common_data.cart_data.is_order_pay = true;
          } else {
            this.helper.common_data.cart_data.is_order_pay = false;
            this.location.replaceState('home');
          }
        } else {
          this.helper.common_data.cart_data.is_order_pay = false;
        }
        var res_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STORE_DETAIL, {store_id: this.helper.website_data.detail.store_id}, true, true, true, true)
          this.helper.loader_service.main_loading = false;
          this.current_store = res_data.store_detail;
          if(res_data.success){
            this.helper.common_data.cart_data.timezone = res_data.timezone;
            this.helper.common_data.cart_data.server_date = res_data.server_time;
            this.delivery_setting_service.is_emergency = res_data.is_emergency
            this.delivery_setting_service.emergency_close_till = res_data.emergency_close_till
            this.current_store.offers.forEach((offer_detail:any)=>{
              if(offer_detail.offer_type==1){
                if(this.helper.website_data.selected_language=='en'){
                  offer_detail.offer_tag = offer_detail.offer_tag + ' on certain products';
                } else {
                  offer_detail.offer_tag = offer_detail.offer_tag + ' sur certains produits';
                }
              } else if(offer_detail.offer_type==2){
                if(this.helper.website_data.selected_language=='en'){
                  offer_detail.offer_tag = offer_detail.offer_tag + ' on certain products';
                } else {
                  offer_detail.offer_tag = offer_detail.offer_tag + ' sur certains produits'
                }
              } else if(offer_detail.offer_type==3){
                if(offer_detail.discount_type==2){
                  if(this.helper.website_data.selected_language=='en'){
                    offer_detail.offer_tag = 'Free delivery costs';
                  } else {
                    offer_detail.offer_tag = 'Frais de livraisons offerts';
                  }
                }
                if(offer_detail.is_min_order_price){
                  if(this.helper.website_data.selected_language=='en'){
                    offer_detail.offer_tag = offer_detail.offer_tag + ' min. '+offer_detail.min_order_price + ' ' + this.current_store.wallet_currency_code
                  } else {
                    offer_detail.offer_tag = offer_detail.offer_tag + ' de min. '+offer_detail.min_order_price + ' ' + this.current_store.wallet_currency_code
                  }
                }
              } else if(offer_detail.offer_type==4){
                
                if(offer_detail.is_min_order_price){
                  if(this.helper.website_data.selected_language=='en'){
                    offer_detail.offer_tag = offer_detail.offer_tag + ' min. '+offer_detail.min_order_price + ' ' + this.current_store.wallet_currency_code
                  } else {
                    offer_detail.offer_tag = offer_detail.offer_tag + ' de min. '+offer_detail.min_order_price + ' ' + this.current_store.wallet_currency_code
                  }
                }
              }
              this.offer_list.push(offer_detail.offer_tag)
            })
            let date:any = res_data.server_time;
                date = new Date(date).toLocaleString("en-US", {timeZone: res_data.timezone})
                date = new Date(date);
            this.delivery_setting_service.check_open(this.current_store, date, true, true);
            this.delivery_setting_service.check_and_set_schedule(this.current_store);
          }
      }
    });

    this.session_subscriber = this.helper.common_data.session_observable.subscribe((data)=>{
      this.check_detail();
    });
  }

  ngOnDestroy(){
    if(this.session_subscriber){
      this.session_subscriber.unsubscribe();
    }
  }

  check_detail(){
    if(localStorage['user']){
      let user = JSON.parse(localStorage['user']);
      if(user && user._id){
          this.user_id = user._id;
          this.server_token = user.server_token;
          this.delivery_setting_service.get_fav_address({user_id: this.user_id, server_token:this.server_token});
      }
    } else {
      this.user_id = '';
      this.server_token = '';
    }
  }

  set_schedule(){
    this.delivery_setting_service.change_schedule(true, this.current_store)
    if(this.current_store.close || this.delivery_setting_service.schedule_time_error || (!this.delivery_setting_service.is_schedule_order && this.current_store.is_only_schedule_order)){
      if(this.delivery_setting_service.date_array.length>0){
        this.delivery_setting_service.set_date(this.delivery_setting_service.date_array[0], this.current_store)
        if(this.delivery_setting_service.time_array[0]){
          this.delivery_setting_service.schedule_time = this.delivery_setting_service.time_array[0].time_format;
        } else {
          this.delivery_setting_service.date_array.splice(0, 1);
          this.delivery_setting_service.set_date(this.delivery_setting_service.date_array[0], this.current_store)
          this.delivery_setting_service.schedule_time = this.delivery_setting_service.time_array[0].time_format;
        }
        this.delivery_setting_service.change_schedule(true, this.current_store);
      }
    }
  }

  go_to_next(ipickup_delivery:boolean){
    this.helper.common_data.cart_data.ipickup_delivery = ipickup_delivery;
    if(this.current_store.close || this.delivery_setting_service.schedule_time_error || (!this.delivery_setting_service.is_schedule_order && this.current_store.is_only_schedule_order)){
      if(this.delivery_setting_service.date_array.length>0){
        this.delivery_setting_service.set_date(this.delivery_setting_service.date_array[0], this.current_store)
        if(this.delivery_setting_service.time_array[0]){
          this.delivery_setting_service.schedule_time = this.delivery_setting_service.time_array[0].time_format;
        } else {
          this.delivery_setting_service.date_array.splice(0, 1);
          this.delivery_setting_service.set_date(this.delivery_setting_service.date_array[0], this.current_store)
          this.delivery_setting_service.schedule_time = this.delivery_setting_service.time_array[0].time_format;
        }
        this.delivery_setting_service.change_schedule(true, this.current_store);
      }
    }
    this.page_type = 2;
    if(!ipickup_delivery){
      setTimeout(()=>{
        this.delivery_setting_service.load_autocomplete(this.current_store, true)
      },100);
    }
    // this.delivery_setting_service.check_open(this.current_store, this.helper.common_data.cart_data.schedule_date, false, true)
    this.delivery_setting_service.check_and_set_schedule(this.current_store);
    if(this.helper.common_data.cart_data.is_schedule_order){
      this.delivery_setting_service.check_open(this.current_store, this.helper.common_data.cart_data.schedule_date, false, true);
    } else {
      let date:any = this.helper.common_data.cart_data.server_date;
        date = new Date(date).toLocaleString("en-US", {timeZone: this.helper.common_data.cart_data.timezone})
        date = new Date(date);
      this.delivery_setting_service.check_open(this.current_store, date, true, true);
    }
    this.delivery_setting_service.calculate_delivery_time(this.current_store.preparation_time_for_pickup, this.current_store.preparation_time_for_delivery)
    if(this.helper.common_data.cart_data.is_order_pay){
      this.go_to_shop_page();
    }
  }

  go_to_shop_page(){
    if(((this.current_store.is_pickup_possible && this.helper.common_data.cart_data.ipickup_delivery) || (this.current_store.is_delivery_possible && !this.helper.common_data.cart_data.ipickup_delivery)) && (!this.delivery_setting_service.schedule_time_error || !this.delivery_setting_service.is_schedule_order) && ((this.helper.common_data.cart_data.destination_address.location && this.helper.common_data.cart_data.destination_address.location[0] && this.helper.common_data.cart_data.destination_address.location[1]) || this.helper.common_data.cart_data.ipickup_delivery)){
      if(this.helper.common_data.cart_data.is_order_pay){
        this.helper.router.navigate(['shop/table/'+this.helper.common_data.cart_data.table_no]);
      } else {
        this.helper.router.navigate(['shop']);
      }
      this.helper.common_data.observable.next({
        current_store: this.current_store,
        // is_close_asap: this.delivery_setting_service.is_close_asap,
        schedule_time: this.delivery_setting_service.schedule_time,
        schedule_date: this.delivery_setting_service.schedule_date,
        clicked_date: this.delivery_setting_service.clicked_date,
      });
    } else {
      if((!this.helper.common_data.cart_data.destination_address.location || !this.helper.common_data.cart_data.destination_address.location[0] || !this.helper.common_data.cart_data.destination_address.location[1]) && !this.helper.common_data.cart_data.ipickup_delivery){
        this.delivery_setting_service.is_address_valid = false;
      }
    }
  }

  download_menu(){
    window.open(this.helper.image_base_url + this.current_store.menu_url, '_blank');
  }

  go_to_menu(){
    this.helper.router.navigate([]).then(result => {  window.open('/menu', '_blank'); });
  }

  get_style(){
    let style = {
      'background': 'linear-gradient(rgba(0,0,0,'+(this.current_store.background_opacity/100)+'), rgba(0,0,0,'+(this.current_store.background_opacity/100)+')),' + (this.current_store.background_type==1 ? 'url(' + this.helper.image_base_url + this.current_store.image_url + ')' : this.current_store.background_color) + ' no-repeat',
      'background-size' : 'cover'
    }
    return style;
  }

}
