export class cartAddress {
    delivery_status:number = 0;
    address_type:string = '';
    address:string = "";
    floor:string = "";
    entry_code:string = "";
    city:string = "";
    location:Array<number> = [0,0];
    note:string = "";
    user_type:number = 0;
    user_details:any  = {
        name:"",
        country_phone_code:"",
        phone:"",
        email:""
    }

    constructor(address_type:string){
        this.address_type = address_type;
    }

}

export class CartData{
    cart: Array<any> = [];
    cart_id: string = '';
    city_id: string = '';
    order_payment_id: string = '';
    destination_addresses: Array<cartAddress> = [];
    pickup_addresses: Array<cartAddress> = [];
    max_item_quantity_add_by_user:number = 0
    selectedStoreId:string = '';
    total_item: number = 0;
    total_cart_amount:number = 0;

    constructor(){
        this.cart = [];
        this.cart_id = '';
        this.city_id = '';
        this.destination_addresses = [];
        this.pickup_addresses = [];
        this.selectedStoreId = '';
        this.total_item = 0;
        this.total_cart_amount = 0;
        this.max_item_quantity_add_by_user = 0;
        this.order_payment_id = '';
    }

}

export class Cart {
    cart_data : CartData = new CartData();
    cart_main_item:any[] = []
    
    constructor(){
        this.cart_data = new CartData();
    }
    
}



