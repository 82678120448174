import { Component, OnInit, TemplateRef, ViewChild, OnDestroy, HostListener } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Helper } from '../shared/helper';
declare var google:any;

@Component({
  selector: 'app-delivery-error-modal',
  templateUrl: './delivery-error-modal.component.html',
  styleUrls: ['./delivery-error-modal.component.scss']
})
export class DeliveryErrorModalComponent implements OnInit, OnDestroy {

  constructor(private modalService: NgbModal, public helper: Helper) { }
  @ViewChild('NotDeliver') NotDeliver!: TemplateRef<any>;
  @ViewChild('ScopeOfDelivery') ScopeOfDelivery!: TemplateRef<any>;

  city_list: any[] = [];
  map:any;
  NotDeliver_subscriber:any;
  email:string='';
  modal_ref:any;
  @HostListener('window:popstate', ['$event'])
    onBrowserBackBtnClose(event: Event) {
      if(this.modal_ref){
        this.modal_ref.close();
      }
    }

  ngOnInit(): void {
    this.NotDeliver_subscriber = this.helper.common_data.NotDeliver_observable.subscribe((data)=>{
      if(data && this.NotDeliver_subscriber){
        this.NotDeliver_subscriber.unsubscribe();
        this.openModal()
      }
    })
  }

  ngOnDestroy(){
    this.NotDeliver_subscriber.unsubscribe();
  }

  openModal() {
    this.modal_ref = this.modalService.open(this.NotDeliver, { centered: true, size: 'lg', modalDialogClass: 'notdeliver-popup see-menu-popup popup-mid', });
  }

  async openModal1() {
    this.modal_ref = this.modalService.open(this.ScopeOfDelivery, { centered: true, size: 'lg', modalDialogClass: 'ScopeOfDelivery-popup popup-lg', });
    this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 10,
        streetViewControl: false,
        center: {lat: 46.2044, lng: 6.1432}
    });
    var response_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_CITY_LIST, {store_id: this.helper.website_data.detail.store_id, city_id: this.helper.website_data.detail.city_id}, true, true, false, false);
    if(response_data.success){
      this.city_list = response_data.city_list;
    }
    this.city_list.forEach((city) => {
      if(city.is_business){
        this.map.setCenter({lat: city.city_lat_long[0], lng: city.city_lat_long[1]})
        if(response_data.store_detail && !response_data.store_detail.is_provide_delivery_anywhere){
          var cityCircle = new google.maps.Circle({
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
              map: this.map,
              center: {lat: response_data.store_detail.location[0], lng: response_data.store_detail.location[1]},
              radius: response_data.store_detail.delivery_radius * 1000
          });
        } else {
          if (city.is_use_radius) {
            var cityCircle = new google.maps.Circle({
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
                map: this.map,
                center: {lat: city.city_lat_long[0], lng: city.city_lat_long[1]},
                radius: city.city_radius * 1000
            });

          } else {
              let city_location = city.city_locations;
              let array:any[] = [];
              city_location.forEach((location:any) => {
                  array.push({lat: Number(location[1]), lng: Number(location[0])})
              });
              let polygon = new google.maps.Polygon({
                  map: this.map,
                  paths: array,
                  strokeColor: 'black',
                  strokeOpacity: 1,
                  strokeWeight: 1.2,
                  fillColor: 'black',
                  fillOpacity: 0.3,
                  draggable: false,
                  geodesic: true,
                  editable: false
              });
          }
        }

      }
    });
  }

}
